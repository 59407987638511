import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";
import { useSwipeable } from "react-swipeable";
import getLocalizedSlug from "src/utils/getLocalizedSlug";
import Settings from "src/stores/Settings";
import { navigate } from "gatsby";

import { SubHeaderM, SubHeaderS, LabelS, LabelXS } from "src/atoms/Typography";
import { colors, spacing, mediaquery, fontWeights } from "src/styles/variables";
import { LOAD_ANIMATIONS } from "./constants";
import Picture, { FITS } from "src/atoms/Picture";
import ProductFlavorsCarousel from "./ProductFlavorsCarousel";

const ProductFlavorsCard = ({
  categoryTitle,
  categorySubtitle,
  subcategoryTitle,
  flavorsList,
  parentCardType,
  flavorsRelativeSlug,
}) => {
  const { locale } = useContext(Settings);
  const [currentFlavorIdx, setCurrentFlavorIdx] = useState(0);
  const [isFlavorCardHover, setIsFlavorCardHover] = useState(false);
  const { flavorSingleLabel } = useContext(Settings).translations;
  const { flavorPluralLabel } = useContext(Settings).translations;
  const defaulflavorSingleLabel = flavorSingleLabel || "Flavor";
  const defaulflavorPluralLabel = flavorPluralLabel || "Flavors";
  const flavorName = useRef(null);
  const [flavorNameWidth, setFlavorNameWidth] = useState(0);
  const prevFlavorNameWidth = useRef(0);

  useLayoutEffect(() => {
    alignFlavorNameWidth();
    var flavorNameWidthInterval = setInterval(function () {
      if (prevFlavorNameWidth.current !== flavorName.current.offsetWidth) {
        alignFlavorNameWidth();
      } else {
        clearInterval(flavorNameWidthInterval);
      }
    }, 250);
  }, [currentFlavorIdx, flavorName.current]);

  const alignFlavorNameWidth = () => {
    // console.log(
    //   flavorName,
    //   prevFlavorNameWidth.current,
    //   flavorName.current.offsetWidth
    // );
    setFlavorNameWidth(flavorName.current.offsetWidth);
    prevFlavorNameWidth.current = flavorName.current.offsetWidth;
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => {
      if (currentFlavorIdx < flavorsList.length - 1) {
        const nextFlavorIdx = currentFlavorIdx + 1;
        setCurrentFlavorIdx(nextFlavorIdx);
      }
    },
    onSwipedRight: (e) => {
      if (currentFlavorIdx > 0) {
        const prevFlavorIdx = currentFlavorIdx - 1;
        setCurrentFlavorIdx(prevFlavorIdx);
      }
    },
  });

  const cardMouseOverHandler = (e) => {
    setIsFlavorCardHover(true);
  };

  const cardMouseLeaveHandler = (e) => {
    setIsFlavorCardHover(false);
  };

  const updatedFlavorsList = flavorsList.map((flavor) => {
    // console.log(`${flavorsRelativeSlug}${flavor.slug}`);
    return {
      ...flavor,
      slug: getLocalizedSlug(`${flavorsRelativeSlug}${flavor.slug}`, locale),
    };
  });

  const navigateToFlavor = (flavorSlug) => {
    const { navigationHistory } = (history && history.state) || {};
    const splittedUrl = window.location.href.split("/");
    const currentSlug = getLocalizedSlug(
      `/${splittedUrl[splittedUrl.length - 2]}`,
      locale
    );

    navigate(`${flavorSlug}`, {
      state: {
        navigationHistory:
          !navigationHistory || navigationHistory.length === 1
            ? [currentSlug, flavorSlug]
            : [...navigationHistory, currentSlug, flavorSlug],
      },
    });
  };

  return (
    <FlavorsCardOuter
      onMouseOver={cardMouseOverHandler}
      onMouseLeave={cardMouseLeaveHandler}
    >
      <FlavorsCardInner>
        <CurvedContainer {...swipeHandlers}>
          <Curved>
            <Contained>
              {(updatedFlavorsList.length > 1 && (
                <>
                  <ProductFlavorsCarousel
                    flavorsList={updatedFlavorsList}
                    isFlavorCardHover={isFlavorCardHover}
                    currentFlavorIdx={currentFlavorIdx}
                    setCurrentFlavorIdx={setCurrentFlavorIdx}
                  />
                  <ProductFlavorsCarousel
                    flavorsList={updatedFlavorsList}
                    isThumbnailsCarousel
                    isFlavorCardHover={isFlavorCardHover}
                    currentFlavorIdx={currentFlavorIdx}
                    setCurrentFlavorIdx={setCurrentFlavorIdx}
                  />
                </>
              )) ||
                updatedFlavorsList.map((flavor, key) => (
                  <PictureContainer
                    key={key}
                    onClick={() => {
                      navigateToFlavor(flavor.slug);
                    }}
                  >
                    <Picture fit={FITS.contain} small={flavor.image.fluid} />
                  </PictureContainer>
                ))}
            </Contained>
          </Curved>
        </CurvedContainer>
        <FlavorsCardInfo>
          <CategoryTitle>{categoryTitle}</CategoryTitle>
          {!!parentCardType && parentCardType === "category" && (
            <SubcategoryTitle>{subcategoryTitle}</SubcategoryTitle>
          )}
          <CategorySubtitle>{categorySubtitle}</CategorySubtitle>
          <FlavorsInfoContainer>
            <FlavorsCount className={!isFlavorCardHover ? "active" : ""}>
              {updatedFlavorsList.length}{" "}
              {updatedFlavorsList.length !== 1
                ? defaulflavorPluralLabel
                : defaulflavorSingleLabel}
            </FlavorsCount>
            <FlavorLink
              className={isFlavorCardHover ? "active" : ""}
              onClick={() => {
                navigateToFlavor(updatedFlavorsList[currentFlavorIdx].slug);
              }}
              flavorNameWidth={flavorNameWidth}
            >
              <FlavorName
                ref={flavorName}
                flavorcolor={updatedFlavorsList[currentFlavorIdx].color}
              >
                {updatedFlavorsList[currentFlavorIdx].name}
              </FlavorName>
            </FlavorLink>
          </FlavorsInfoContainer>
        </FlavorsCardInfo>
      </FlavorsCardInner>
    </FlavorsCardOuter>
  );
};

ProductFlavorsCard.propTypes = {
  categoryTitle: PropTypes.string,
  categorySubtitle: PropTypes.string,
  subcategoryTitle: PropTypes.string,
  flavorsList: PropTypes.arrayOf(PropTypes.object),
  parentCardType: PropTypes.string,
  flavorsRelativeSlug: PropTypes.string,
};

const FlavorsCardOuter = styled.div`
  outline: 0;
  user-select: none;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0 4px 10px 2px ${colors.translucidBlack};
  overflow: hidden;
  margin-bottom: ${spacing.default.lg};
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeInAnimation = css`
  opacity: 0;
  animation: ${fadeIn} ${LOAD_ANIMATIONS.fade} cubic-bezier(0.47, 0, 0.37, 1)
    ${LOAD_ANIMATIONS.fadeDelay} forwards;
`;

const FlavorsCardInner = styled.div`
  display: Flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  ${fadeInAnimation}
`;

const CurvedContainer = styled.div`
  flex: 0 0 auto;
  position: relative;
  z-index: 20;
  overflow: hidden;
`;

const Curved = styled.div`
  position: relative;
  background: ${colors.white};
  width: 120%;
  left: -10%;
  height: 250px;
  overflow: hidden;
  border-radius: 0 0 50% 50% / 0 0 30px 30px;
`;

const Contained = styled.div`
  width: calc(80% + 20px);
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const PictureContainer = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .gatsby-image-wrapper {
    max-width: 90%;
    max-height: 90%;
  }
`;

const FlavorsCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  position: relative;
  z-index: 10;
  background: ${colors.activiaMediumGreen};
  padding: calc(${spacing.default.lg} + ${spacing.default.md})
    ${spacing.default.md} ${spacing.default.md};
  margin-top: -${spacing.default.lg};
  text-align: center;

  ${mediaquery.md(css`
    align-items: flex-start;
    text-align: left;
  `)}
`;

const CategoryTitle = styled(SubHeaderM)`
  color: ${colors.white};
  margin: ${spacing.stack.xs};
`;

const SubcategoryTitle = styled(SubHeaderS)`
  color: ${colors.white};
  margin: ${spacing.stack.xs};
`;

const CategorySubtitle = styled(LabelS)`
  color: ${colors.white};
  margin: ${spacing.stack.xs};
`;

const FlavorsInfoContainer = styled.div`
  display: flex;
  align-items: center;
  // width: 100%;
  justify-content: center;
  // padding-left: 50%;
  text-align: left;

  ${mediaquery.md(css`
    position: relative;
    justify-content: flex-start;
    width: 100%;
  `)};
`;

const FlavorsCount = styled(LabelXS)`
  color: ${colors.white};
  font-weight: ${fontWeights.medium};
  text-transform: uppercase;
  margin-right: 10px;
  position: relative;
  flex: 0 0 auto;
  // margin-left: -50%;

  ${mediaquery.md(css`
    display: block;
    position: absolute;
    transform: translateY(5px);
    opacity: 0;
    visibility: hidden;
    z-index: -10;
    // width: 0;
    top: 0;
    left: 0;
    transition: all 0.2s, width 0s, z-index 0s ease 0.2s;

    &.active {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      z-index: 0;
      width: auto;
      transition: all 0.2s ease 0.25s, width 0s ease 0.25s,
        z-index 0s ease 0.25s;
    }
  `)};
`;

const FlavorLink = styled.button`
  position: relative;
  text-decoration: none;
  border-radius: 5px;
  flex: 1;
  overflow: hidden;
  text-align: left;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    // left: ${({ flavorNameWidth }) =>
      flavorNameWidth > 0 ? `${flavorNameWidth / 2}px` : "50%"};
    bottom: 0;
    width: ${({ flavorNameWidth }) =>
      flavorNameWidth > 0 ? flavorNameWidth + "px" : "100%"};
    background: ${colors.white};
    border-radius: 5px;
    transform: translateX(-50%);
    // transform: ${({ flavorNameWidth }) =>
      flavorNameWidth > 0
        ? `translateX(-${flavorNameWidth / 2}px)`
        : "translateX(-50%)"};
    transition: all 0.5s;
  }

  ${mediaquery.md(css`
    position: relative;
    display: inline-block;
    transform: translateY(5px);
    opacity: 0;
    visibility: hidden;
    z-index: -10;
    transition: all 0.2s, z-index 0s ease 0.2s;

    &:before {
      left: 0;
      transform: none;
    }

    &.active {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      z-index: 0;
      transition: all 0.2s ease 0.25s, z-index 0s ease 0.25s;
    }
  `)};
`;

const FlavorName = styled(LabelXS)`
  position: relative;
  font-weight: ${fontWeights.bold};
  color: ${({ flavorcolor }) => (flavorcolor ? flavorcolor : colors.white)};
  text-transform: uppercase;
  transition: color 0.2s;
  z-index: 1;
  padding: 5px 8px;
  display: inline-block;
  box-sizing: border-box;
`;

export default ProductFlavorsCard;
